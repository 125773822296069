import React from 'react'
import {
  YoutubeEmbedStyled,
  YoutubeEmbedIframeStyled,
} from './YoutubeEmbed.styles'

interface YoutubeEmbedProps {
  embedVideo: string
}

export const YoutubeEmbed = ({ embedVideo }: YoutubeEmbedProps) => (
  <YoutubeEmbedStyled>
    <YoutubeEmbedIframeStyled
      width="853"
      height="480"
      src={`${embedVideo}?enablejsapi=1&origin=${window.location.origin}`}
    />
  </YoutubeEmbedStyled>
)
