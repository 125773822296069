import { observer } from 'mobx-react'
import styled from 'styled-components'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { ContentContainer, Header } from '../components/Generic'
import { DefaultError } from '../components/Error/DefaultError'
import { DefaultErrorIkano } from '../components/Error/DefaultErrorIkano'
import { AlreadyUsedErrorIkano } from '../components/Error/AlreadyUsedErrorIkano'
import { SessionExpiredErrorIkano } from '../components/Error/SessionExpiredErrorIkano'
import { TransactionExpiredError } from '../components/Error/TransactionExpiredError'
import { ParagraphCentered } from '../styles/generic.styles'

const PositionedIllustration = styled.div`
  width: fit-content;
  margin: 0 auto;
`

export const ErrorPage = observer(() => {
  const store = useContext(StoreContext)
  const { errorPage: trans } = store.TranslationsState.translations
  const { themeId, theme } = store.InterfaceState
  const [error, setError] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  const [errorType, setErrorType] = useState('')
  const [errorIlustration, setErrorIlustration] =
    useState<JSX.Element>(undefined)

  useEffect(() => {
    const path = window.location.pathname.split('/')[1]
    if (path.includes('error-session-expired')) {
      setError(trans.sessionExpiredTitle)
      setErrorType('session-expired')
    } else if (path.includes('error-transaction-expired')) {
      setError(trans.transactionExpiredTitle)
      setErrorDescription(trans.transactionExpiredDescription)
      setErrorType('transaction-expired')
    } else if (path.includes('error-already-used')) {
      setError(trans.alreadyUsedTitle)
      setErrorType('already-used')
    } else {
      setError(trans.title)
      setErrorType('default')
    }
  }, [trans])

  useEffect(() => {
    setErrorIlustration(selectIlustration())
  }, [errorType])

  const selectIlustration = () => {
    if (errorType === 'transaction-expired') return <TransactionExpiredError />

    if (themeId === 'ikanoTheme' || themeId === 'ikanoFleksTheme') {
      if (errorType === 'session-expired') return <SessionExpiredErrorIkano />
      else if (errorType === 'already-used') return <AlreadyUsedErrorIkano />
      else return <DefaultErrorIkano />
    }

    return <DefaultError />
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      padding="50px 110px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <Header
        {...theme.header}
        margin="0 0 30px"
        fontFamily={theme.globals.fontFamilyHeadline}
      >
        {error}
      </Header>
      {errorDescription && (
        <ParagraphCentered>{errorDescription}</ParagraphCentered>
      )}

      <PositionedIllustration>{errorIlustration}</PositionedIllustration>
    </ContentContainer>
  )
})
