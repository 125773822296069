import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'
import { Language, TranslationsInterface } from '../types/translations'
import { RootStore } from './Root.store'
import { devLog } from '@/methods/devLog'

const clientLangThemeMap = {
  ikanoTheme: 'ikano',
  bmwTheme: 'bmw',
  miniTheme: 'bmw',
}

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

function resolveLanguage(language: string, themeId?: string): string {
  if (!themeId) return language

  // get client name from themeId
  const client = clientLangThemeMap[themeId]
  // get custom client language name
  const computedLanguage = `${language}${capitalize(client)}`
  // verify if the custom client language exists
  if (client && translations[computedLanguage]) {
    return computedLanguage
  } else {
    return language
  }
}

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = translations.en
  language = 'en'
  paramLanguage = 'en'

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTranslations(baseLanguage: Language): void {
    this.language = baseLanguage.substring(0, 2)

    const lang = resolveLanguage(
      this.language,
      this.rootStore.InterfaceState.themeId
    )

    devLog('Resolved translation property: ', lang)

    this.paramLanguage = lang

    this.translations = { ...translations.en, ...translations[lang] }
  }
}
