import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
  Popup,
} from '../Generic'
import CryptoJS from 'crypto-js'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../App'
import { actionReport } from '../../methods/actionReport'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import {
  ButtonImgWrap,
  ButtonText,
  Condition,
  ConditionTitle,
  RequiredFooter,
} from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerWithScroll,
} from '../../styles/layout.styles'
import { ConsentsRes } from '../../types/acceptanceDocument'
import { forceRedirect } from '../../methods/forceRedirect'

interface TermsAndConditionsProps {
  showHeader?: boolean
  header?: string
  additionalParagraph?: JSX.Element
  showTitle?: boolean
  buttonText?: string
}

export const TermsAndConditions = observer(
  ({
    showHeader = true,
    header,
    additionalParagraph,
    showTitle = true,
    buttonText,
  }: TermsAndConditionsProps) => {
    const store = useContext(StoreContext)

    const { pageWidth, pageHeight } = store.AppState
    const { theme } = store.InterfaceState
    const { currentScenarioId } = store.ScenarioState
    const { generalConditionsPage: trans } =
      store.TranslationsState.translations

    // Checkboxes state
    const [consents, setConsents] = useState([])
    const [consentPdf, setConsentPdf] = useState(null)
    const [requiredConsents, setRequiredConsents] = useState([])
    const [acceptedConsents, setAcceptedConsents] = useState(new Set())
    const [proceedDisabled, setProceedDisabled] = useState(true)

    // Popups visibility
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupDocument, setPopupDocument] = useState({
      serviceType: null,
      documentType: null,
    })

    // Loadings state
    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)

    // Mobile utilities
    const [isMobile, setIsMobile] = useState(false)
    const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')
    const [mobileContainerHeight, setMobileContainerHeight] = useState('')

    useEffect(() => {
      if (pageWidth && pageWidth <= 980) {
        setMobileContainerHeight(`calc(${window.innerHeight}px - 120px)`)
        setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
        setIsMobile(true)
      }
    }, [pageWidth, pageHeight])

    const getDocuments = async () => {
      try {
        const apiUrl = process.env.WEB_API_URL

        const res = await axiosInstance.get<ConsentsRes>(
          `${apiUrl}/terms-and-conditions/consents`,
          { withCredentials: true }
        )

        setConsents(res.data.consents)

        const requiredConsents = res.data.consents.reduce((acc1, consent) => {
          const docs = consent.documents.reduce((acc, document) => {
            if (!document.optional) {
              acc.push(`${consent.serviceType}_${document.documentType}`)
            }

            return acc
          }, [])

          return acc1.concat(docs)
        }, [])

        setRequiredConsents(requiredConsents)
        setProceedDisabled(requiredConsents.length > 0)
        setLoading(false)
      } catch (e) {
        devLog(e)
      }
    }

    const handleProceed = async () => {
      const apiUrl = process.env.WEB_API_URL

      if (![...requiredConsents].every((val) => acceptedConsents.has(val)))
        return

      try {
        if (currentScenarioId !== 'localTests') {
          setBtnLoading(true)
          const tempConsents = consents.map((consent) => {
            const tempDocuments = consent.documents.map(({ file, ...rest }) => {
              return {
                ...rest,
                digest: CryptoJS.SHA256(
                  CryptoJS.enc.Base64.parse(file)
                ).toString(CryptoJS.enc.Hex),
                accepted: acceptedConsents.has(
                  `${consent.serviceType}_${rest.documentType}`
                ),
              }
            })

            return {
              serviceType: consent.serviceType,
              documents: tempDocuments,
            }
          })

          await axiosInstance.post(
            `${apiUrl}/terms-and-conditions/consents/accept`,
            {
              consents: tempConsents,
            },
            {
              withCredentials: true,
            }
          )

          forceRedirect(`${apiUrl}/terms-and-conditions/complete`)
        }
      } catch (e) {
        setBtnLoading(false)
        devLog(e)
      }
    }

    const handleConsent = ({ checked, id }) => {
      const currentAcceptedConsents = acceptedConsents

      if (checked) {
        currentAcceptedConsents.add(id)
      } else {
        currentAcceptedConsents.delete(id)
      }

      setAcceptedConsents(currentAcceptedConsents)

      setProceedDisabled(
        ![...requiredConsents].every((val) => currentAcceptedConsents.has(val))
      )
    }

    useEffect(() => {
      if (window.location.pathname.split('/')[1] !== 'mock') {
        getDocuments()
      }
    }, [])

    useEffect(() => {
      if (popupVisible === true) {
        actionReport({
          type: 'event.onboarding-web.consent.DOCUMENT_DISPLAYED',
          payload: {
            serviceType: popupDocument.serviceType,
            documentType: popupDocument.documentType,
          },
        })
      }
    }, [popupVisible])

    const isTouchScrollDisabled = () => {
      if (popupVisible) {
        return 'none'
      } else {
        return 'auto'
      }
    }

    return (
      <ContentContainer
        {...theme.container}
        width="560px"
        paddingMobile="20px"
        marginMobile="0"
        touchAction={isTouchScrollDisabled()}
      >
        {loading ? (
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            height="68px"
            {...theme.loadingSpinner}
          />
        ) : (
          <>
            <ContentContainerWithScroll
              {...(isMobile && {
                mobileContainerHeight: mobileContainerHeight,
              })}
            >
              {showHeader && (
                <Header
                  {...theme.header}
                  fontFamily={theme.globals.fontFamilyHeadline}
                >
                  {header ?? trans.header}
                </Header>
              )}

              {additionalParagraph && additionalParagraph}

              {consents &&
                consents.map((consent) =>
                  consent.documents.map(
                    (document) =>
                      !document.optional && (
                        <Condition
                          margin="30px 0 10px"
                          key={`${consent.serviceType}_${document.documentType}`}
                        >
                          {document.title && showTitle && (
                            <ConditionTitle required={!document.optional}>
                              {document.title}
                            </ConditionTitle>
                          )}
                          <DocumentCheckbox
                            inputName={`${consent.serviceType}_${document.documentType}`}
                            description={document.label}
                            externalUrl={document.filename}
                            handleDecision={handleConsent}
                            value={document.accepted || false}
                            {...theme.documentCheckbox}
                            margin="0 0 30px 0"
                            contentFontsize="14px"
                            iconSize="26px"
                            iconColor={theme.globals.checkboxColor}
                            onClick={() => {
                              setConsentPdf(document.file)
                              setPopupVisible(true)
                              setPopupDocument({
                                serviceType: consent.serviceType,
                                documentType: document.documentType,
                              })
                            }}
                          />
                        </Condition>
                      )
                  )
                )}

              {consents &&
                consents.map((consent) =>
                  consent.documents.map(
                    (document) =>
                      document.optional && (
                        <Condition
                          margin="30px 0 10px"
                          key={`${consent.serviceType}_${document.documentType}`}
                        >
                          {document.title && showTitle && (
                            <ConditionTitle required={!document.optional}>
                              {document.title}
                            </ConditionTitle>
                          )}
                          <DocumentCheckbox
                            inputName={`${consent.serviceType}_${document.documentType}`}
                            description={document.label}
                            externalUrl={document.filename}
                            handleDecision={handleConsent}
                            value={document.accepted || false}
                            {...theme.documentCheckbox}
                            margin="0 0 30px 0"
                            contentFontsize="14px"
                            iconSize="26px"
                            onClick={() => {
                              setConsentPdf(document.file)
                              setPopupVisible(true)
                              setPopupDocument({
                                serviceType: consent.serviceType,
                                documentType: document.documentType,
                              })
                            }}
                          />
                        </Condition>
                      )
                  )
                )}
            </ContentContainerWithScroll>

            <ContentContainerBottomFixed>
              {showTitle && <RequiredFooter>{trans.required}</RequiredFooter>}

              <Button
                onClick={handleProceed}
                disabled={proceedDisabled}
                {...theme.button}
                width="164px"
                paddingMobile="14px 40px"
                id="page-submit"
              >
                {btnLoading ? (
                  <LoadingSpinner
                    width="16px"
                    {...theme.loadingSpinner}
                    padding="0 0"
                    thickness={2}
                  />
                ) : (
                  <>
                    <ButtonImgWrap>
                      <Icon size="18px" type="checkmark" />
                    </ButtonImgWrap>
                    <ButtonText>{buttonText ?? trans.proceed}</ButtonText>
                  </>
                )}
              </Button>
            </ContentContainerBottomFixed>
            <>
              <Popup
                visibility={popupVisible}
                handleVisibility={setPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={`data:application/pdf;base64,${consentPdf}`}
                  {...theme.pdfViewer}
                  canvasWidth={isMobile ? 348 : 728}
                  viewerWidth="100%"
                  viewerHeight={
                    isMobile ? containerPdfViewerHeight : `calc(100vh - 405px)`
                  }
                  initialScale={1}
                  margin="30px 0 0 0"
                  containerPadding="0px"
                  navigationSpacerColor="none"
                />
              </Popup>
            </>
          </>
        )}
      </ContentContainer>
    )
  }
)
