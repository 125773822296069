import { Button, Header, LoadingSpinner } from '../../Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import { QRCodeSVG } from 'qrcode.react'
import React, { useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../../App'

import {
  ButtonText,
  Divider,
  IntroParagraph,
} from '../../../styles/generic.styles'

import { usePageVisibility } from '../../../hooks/usePageVisibility'
import { actionReport } from '../../../methods/actionReport'
import { devLog } from '../../../methods/devLog'
import { forceRedirect } from '../../../methods/forceRedirect'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'
import { Margined } from '../../../styles/layout.styles'
import {
  Accordion,
  AccordionTitle,
  AccordionWrapper,
  CenteredSMSText,
} from '../SwitchToMobile.styles'
import { YoutubeEmbed } from '../../../components/YoutubeEmbed/YoutubeEmbed'

interface ScanQRProps {
  nextStep: () => void
  stayUrl?: string
  smsUrl?: string
  smsMaxAttempts: number
  isSmsEnabled?: boolean
  isCameraAvailable?: boolean
  retrySeconds?: number
  getProceedLinkUrl: string
  isMobileSessionStartedUrl: string
}

export const ScanQR = observer(
  ({
    nextStep,
    stayUrl,
    smsUrl,
    smsMaxAttempts,
    isSmsEnabled,
    isCameraAvailable,
    retrySeconds,
    getProceedLinkUrl,
    isMobileSessionStartedUrl,
  }: ScanQRProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { context } = store.ScenarioState
    const { SwitchToMobile: trans } = store.TranslationsState.translations
    const shouldShowAccordion = isSmsEnabled || isCameraAvailable
    const instructionalVideoUrl = context?.instructionalVideoUrl

    const [disabled, setDisabled] = useState(false)
    const [isAccordionOpen, setIsAccordionOpen] = useState(false)
    const [isVideoAccordionOpen, setIsVideoAccordionOpen] = useState(false)
    const [isSendSmsButtonVisible, setIsSendSmsButtonVisible] = useState(true)
    const [sendSmsCount, setSendSmsCount] = useState(0)
    const [QRCodeUrl, setQRCodeUrl] = useState('' as string)

    const [counting, setCounting] = useState(false)
    const [countdownProgress, setCountdownProgress] = useState(retrySeconds)
    const [shouldPoll, setShouldPoll] = useState(true)

    const [auditLogSent, setAuditLogSent] = useState(false)
    const [auditLogVideoSent, setAuditLogVideoSent] = useState(false)

    const isVisible = usePageVisibility()

    const getQRUrl = async () => {
      try {
        const { data } = await axios.get(getProceedLinkUrl, {
          withCredentials: true,
        })

        setQRCodeUrl(data.proceedLink)
        devLog('QRCodeUrl', data.proceedLink)
      } catch (error) {
        devLog(error)
      }
    }

    const pollIsMobileSessionStarted = async () => {
      try {
        const { data } = await axios.get(isMobileSessionStartedUrl, {
          withCredentials: true,
        })
        if (data.isMobileSessionStarted) {
          setShouldPoll(false)
          nextStep()
        }
      } catch (error) {
        devLog(error)
      }
    }

    const handleTimeCount = () => {
      if (countdownProgress > 0) {
        setCountdownProgress(countdownProgress - 1)
      } else {
        setCountdownProgress(retrySeconds)
        setCounting(false)
        setIsSendSmsButtonVisible(true)
        setDisabled(false)
      }
    }

    const sendSms = async () => {
      if (!isSmsEnabled) return
      setSendSmsCount(sendSmsCount + 1)
      devLog('smsUrl', smsUrl)
      setIsSendSmsButtonVisible(false)
      setCounting(true)
      setDisabled(true)

      try {
        axios.post(
          smsUrl,
          {},
          {
            withCredentials: true,
          }
        )
      } catch (error) {
        devLog(error)
      }
    }

    const renderSendSmsStatus = () => (
      <>
        {!isSendSmsButtonVisible && (
          <CenteredSMSText fontFamily={theme.globals.fontFamilyBold}>
            <p>{trans.scanQr.smsSent}</p>
          </CenteredSMSText>
        )}

        <Button
          disabled={
            disabled ||
            !isSendSmsButtonVisible ||
            sendSmsCount >= smsMaxAttempts
          }
          onClick={sendSms}
          {...theme.button}
          bgColor="transparent"
          border="1px solid #DCDBE2"
          color="#34353A"
          padding="10px 20px"
        >
          <ButtonText>
            {sendSmsCount < 1
              ? trans.scanQr.sendSmsBtn
              : trans.scanQr.sendSmsAgainBtn}
          </ButtonText>
        </Button>

        {!isSendSmsButtonVisible && sendSmsCount < smsMaxAttempts && (
          <CenteredSMSText fontFamily={theme.globals.fontFamilyBold}>
            <p>{trans.scanQr.smsNotReceived}</p>

            <p>
              {trans.scanQr.sendSmsAgain.replace(
                '<number>',
                countdownProgress.toString()
              )}
            </p>
          </CenteredSMSText>
        )}
      </>
    )

    useEffect(() => {
      actionReport({
        type: 'event.onboarding-web.go-to-mobile.DESKTOP_QR_CODE_PAGE_OPENED',
        payload: {},
      })

      getQRUrl()
    }, [])

    useEffect(() => {
      setShouldPoll(isVisible)
    }, [isVisible])

    useEffect(() => {
      shouldPoll && pollIsMobileSessionStarted()
    }, [shouldPoll])

    useInterval(pollIsMobileSessionStarted, shouldPoll ? 3000 : null)
    useInterval(handleTimeCount, counting ? 1000 : null)

    return QRCodeUrl ? (
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.scanQr.header}
        </Header>

        <IntroParagraph>{trans.scanQr.description1}</IntroParagraph>

        <Margined margin="0 0 30px" />

        <HorizontalIconContainer>
          <QRCodeSVG value={QRCodeUrl} href={QRCodeUrl} data-testid="qrCode" />
        </HorizontalIconContainer>

        <IntroParagraph>{trans.scanQr.description2}</IntroParagraph>

        <Divider margin="30px 0 0 0" width="100%" />

        {shouldShowAccordion && (
          <AccordionWrapper isOpen={isAccordionOpen}>
            <AccordionTitle
              fontFamily={theme.globals.fontFamilyBold}
              className="open-accordion"
              isOpen={isAccordionOpen}
              onClick={() => {
                if (!auditLogSent && !isAccordionOpen) {
                  actionReport({
                    type: 'event.onboarding-web.go-to-mobile.OTHER_CONTINUE_OPTIONS_OPENED',
                    payload: {},
                  })

                  setAuditLogSent(true)
                }

                setIsAccordionOpen(!isAccordionOpen)
              }}
            >
              <h3>
                {isSmsEnabled
                  ? trans.scanQr.otherOptionsTitle
                  : trans.scanQr.stayOnThisDeviceQuestion}
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
              >
                <path
                  d="M1 1.28369L5 5.28369L9 1.28369"
                  stroke="#34353A"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </AccordionTitle>

            <Accordion isOpen={isAccordionOpen}>
              {isSmsEnabled && (
                <>
                  <IntroParagraph>{trans.scanQr.sendSms}</IntroParagraph>

                  {renderSendSmsStatus()}
                </>
              )}

              <>
                <IntroParagraph>
                  {isSmsEnabled ?? `${trans.scanQr.stayOnThisDeviceQuestion}`}
                </IntroParagraph>
                <Button
                  onClick={() => {
                    if (stayUrl?.length && !disabled) {
                      setDisabled(true)
                      forceRedirect(stayUrl)
                    }
                  }}
                  {...theme.button}
                  bgColor="transparent"
                  border="1px solid #DCDBE2"
                  color="#34353A"
                  padding="10px 20px"
                  disabled={disabled}
                >
                  <ButtonText className="proceed">
                    {' '}
                    {trans.scanQr.stayOnThisDeviceBtn}
                  </ButtonText>
                </Button>
              </>
            </Accordion>
          </AccordionWrapper>
        )}

        {instructionalVideoUrl && (
          <>
            <Divider margin="0" width="100%" />

            <AccordionWrapper isOpen={isVideoAccordionOpen}>
              <AccordionTitle
                fontFamily={theme.globals.fontFamilyBold}
                className="open-video-accordion"
                isOpen={isVideoAccordionOpen}
                onClick={() => {
                  if (!auditLogVideoSent && !isVideoAccordionOpen) {
                    actionReport({
                      type: 'event.onboarding-web.go-to-mobile.VIDEO_SECTION_OPENED',
                      payload: {},
                    })

                    setAuditLogVideoSent(true)
                  }

                  setIsVideoAccordionOpen(!isVideoAccordionOpen)
                }}
              >
                <h3>{trans.scanQr.showVideoTitle}</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                >
                  <path
                    d="M1 1.28369L5 5.28369L9 1.28369"
                    stroke="#34353A"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </AccordionTitle>

              <Accordion isOpen={isVideoAccordionOpen}>
                <IntroParagraph>
                  <YoutubeEmbed embedVideo={instructionalVideoUrl} />
                </IntroParagraph>
              </Accordion>
            </AccordionWrapper>
          </>
        )}

        <Divider margin="0" width="100%" />
      </>
    ) : (
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    )
  }
)
