import React, { useContext, useState } from 'react'
import axios from 'axios'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { ButtonText, IntroParagraph } from '../../../styles/generic.styles'
import { Header, OtpInput, Button, LoadingSpinner } from '../../Generic'
import {
  ContentContainerBottomFixed,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '../../../styles/layout.styles'
import { CodeInputWrapper, CenteredSMSText } from '../ResumeFromEmail.styles'
import { forceRedirect } from '../../../methods/forceRedirect'
import { devLog } from '../../../methods/devLog'
import { useInterval } from 'usehooks-ts'
import { apiUrl } from '@/constants/apiUrl'

interface OtpProps {
  nextStep: () => void
  maxAttempts: number
  attemptsLeft: number
  verifyOtpUrl: string
  formActionUrl: string
  isSmsEnabled?: boolean
  sendOtpUrl?: string
  smsMaxAttempts: number
  smsRetrySeconds?: number
  otpAttemptsLeft?: number
}

export const Otp = observer(
  ({
    nextStep,
    maxAttempts,
    attemptsLeft,
    verifyOtpUrl,
    formActionUrl,
    isSmsEnabled,
    sendOtpUrl,
    smsMaxAttempts,
    smsRetrySeconds,
  }: OtpProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { ResumeFromEmailPage: trans } = store.TranslationsState.translations

    const [isCodeBeingChecked, setIsCodeBeingChecked] = useState(false)
    const [isBeingRedirected, setIsBeingRedirected] = useState(false)
    const [isCodeIncorrect, setIsCodeIncorrect] = useState(!!attemptsLeft)

    const [disabled, setDisabled] = useState(false)
    const [isSendSmsButtonVisible, setIsSendSmsButtonVisible] = useState(false)
    const [sendSmsCount, setSendSmsCount] = useState(1)
    const [counting, setCounting] = useState(true)
    const [countdownProgress, setCountdownProgress] = useState(smsRetrySeconds)

    const [otp, setOtp] = useState('')

    const handleTimeCount = () => {
      if (countdownProgress > 0) {
        setCountdownProgress(countdownProgress - 1)
      } else {
        setCountdownProgress(smsRetrySeconds)
        setCounting(false)
        setIsSendSmsButtonVisible(true)
        setDisabled(false)
      }
    }

    const sendSms = async () => {
      if (!isSmsEnabled) return
      setSendSmsCount(sendSmsCount + 1)
      devLog('smsUrl', sendOtpUrl)
      setIsSendSmsButtonVisible(false)
      setCounting(true)
      setDisabled(true)

      try {
        axios.post(
          sendOtpUrl,
          {},
          {
            withCredentials: true,
          }
        )
      } catch (error) {
        devLog(error)
      }
    }

    const renderSendSmsStatus = () => (
      <CenteredSMSText fontFamily={theme.globals.fontFamilyBold}>
        {!isSendSmsButtonVisible && <p>{trans.OtpPage.smsSent}</p>}

        <Button
          disabled={
            disabled ||
            !isSendSmsButtonVisible ||
            sendSmsCount >= smsMaxAttempts
          }
          onClick={sendSms}
          {...theme.button}
          bgColor="transparent"
          border="1px solid #DCDBE2"
          color="#34353A"
          padding="10px 20px"
        >
          <ButtonText>{trans.OtpPage.sendSmsAgainBtn}</ButtonText>
        </Button>

        {!isSendSmsButtonVisible && sendSmsCount < smsMaxAttempts && (
          <>
            <p>{trans.OtpPage.smsNotReceived}</p>

            <p>
              {trans.OtpPage.sendSmsAgain.replace(
                '<number>',
                countdownProgress.toString()
              )}
            </p>
          </>
        )}
      </CenteredSMSText>
    )

    useInterval(handleTimeCount, counting ? 1000 : null)

    return (
      <form action={formActionUrl} method="post">
        <Header
          {...theme.header}
          fontFamily={theme.globals.fontFamilyHeadline}
          margin="30px 0"
        >
          {trans.OtpPage.header}
        </Header>

        <IntroParagraph textAlign="center">
          {trans.OtpPage.description}
        </IntroParagraph>

        <Margined margin="0 0 30px" />

        <CodeInputWrapper
          isLoading={isCodeBeingChecked}
          isError={isCodeIncorrect}
        >
          <OtpInput
            {...theme.otpInput}
            value={otp}
            valueLength={6}
            onChange={(val) => {
              setOtp(val)
              setIsCodeIncorrect(false)
            }}
            marginTop="40px"
            marginBottom="40px"
            inputHeight="68px"
          />

          {isCodeIncorrect && (
            <IntroParagraph> {trans.OtpPage.error1}</IntroParagraph>
          )}

          {attemptsLeft < maxAttempts && attemptsLeft > 1 && (
            <span>
              {trans.OtpPage.attemptsLeft.replace(
                '<number>',
                attemptsLeft.toString()
              )}
            </span>
          )}

          {attemptsLeft === 1 && <span>{trans.OtpPage.lastAttempt}</span>}
        </CodeInputWrapper>

        <input hidden name="otp" value={otp}></input>

        {isSmsEnabled && <>{renderSendSmsStatus()}</>}

        <ContentContainerBottomFixed borderTop="none">
          {!isCodeBeingChecked && !isBeingRedirected ? (
            <Button
              disabled={isCodeBeingChecked || otp.length < 6}
              type="submit"
              {...theme.button}
              width="190px"
              paddingMobile="14px 10px"
              margin="0 0 20px 0"
            >
              <ButtonText>{trans.OtpPage.continue}</ButtonText>
            </Button>
          ) : (
            <LoadingSpinner
              width="40px"
              bgColor="blue"
              padding="100px 0 40px 0"
              height="0"
              {...theme.loadingSpinner}
            />
          )}
        </ContentContainerBottomFixed>

        <MobileSpacerForContentContainerBottomFixed />
      </form>
    )
  }
)
