import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Header, LoadingSpinner } from '../../Generic'
import axios from 'axios'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../../../components/App'
import { devLog } from '../../../methods/devLog'
import { forceRedirect } from '../../../methods/forceRedirect'

interface WaitingForMobileProps {
  isUserJourneyFinishedUrlUrl: string
  closeDesktopSessionUrl: string
}

const WarningLine = styled.h3<{ fontFamily: string }>`
  color: #e04d39;
  width: 100%;
  text-align: center;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0px;
  font-family: ${({ fontFamily }) => fontFamily};
`

export const WaitingForMobile = observer(
  ({
    isUserJourneyFinishedUrlUrl,
    closeDesktopSessionUrl,
  }: WaitingForMobileProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { SwitchToMobile: trans } = store.TranslationsState.translations

    const [shouldPoll, setShouldPoll] = useState(true)

    const pollForRedirect = async () => {
      try {
        const { data } = await axios.get<{ isUserJourneyFinished?: boolean }>(
          isUserJourneyFinishedUrlUrl,
          {
            withCredentials: true,
          }
        )

        if (data?.isUserJourneyFinished) {
          setShouldPoll(false)
          forceRedirect(closeDesktopSessionUrl)
        }
      } catch (error) {
        devLog(error)
      }
    }

    useEffect(() => {
      shouldPoll && pollForRedirect()
    }, [shouldPoll])

    useInterval(pollForRedirect, shouldPoll ? 3000 : null)

    return (
      <>
        <Header
          {...theme.header}
          fontFamily={theme.globals.fontFamilyHeadline}
          margin="30px 0"
        >
          {trans.waitingForMobile.header}
        </Header>

        <WarningLine fontFamily={theme.globals.fontFamily}>
          {trans.waitingForMobile.description}
        </WarningLine>

        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      </>
    )
  }
)
