import { ContentContainer, LoadingSpinner } from '@components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '@components/App'
import { axiosInstance } from '@/methods/axiosConfig'
import { forceRedirect } from '@/methods/forceRedirect'

export const OpenbankingMastercardPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  const initRedirect = async () => {
    const { data } = await axiosInstance.post<{
      redirectUrl: string
    }>(
      `${process.env.WEB_API_URL}/mastercard-openbanking/start-session`,
      {},
      {
        withCredentials: true,
      }
    )

    if (data?.redirectUrl) {
      forceRedirect(data.redirectUrl)
    }
  }

  useEffect(() => {
    void initRedirect()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </ContentContainer>
  )
})
