import { ContentContainer, LoadingSpinner } from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import { devLog } from '../../methods/devLog'
import { axiosInstance } from '../../methods/axiosConfig'
import { forceRedirect } from '../../methods/forceRedirect'
import { useInterval } from 'usehooks-ts'
import { usePageVisibility } from '../../hooks/usePageVisibility'

interface PollingResponse {
  status: string
  shouldWait: boolean
  proceedUrl?: string
}

export const DTPWaitingPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const apiUrl = process.env.WEB_API_URL

  const [shouldWait, setShouldWait] = useState(true)
  const [shouldPoll, setShouldPoll] = useState(true)

  const isVisible = usePageVisibility()

  const checkStatus = async (): Promise<PollingResponse> => {
    try {
      const res = await axiosInstance.get<PollingResponse>(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/waiting`,
        { withCredentials: true }
      )

      if (!res.data.shouldWait) {
        setShouldWait(false)

        // SC-10946 /redirect-from-waiting to be removed after migrating to V3
        const location = res.data.proceedUrl
          ? res.data.proceedUrl
          : `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/redirect-from-waiting`

        forceRedirect(location)
      }

      return res.data
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    setShouldPoll(isVisible)
  }, [isVisible])

  useEffect(() => {
    shouldWait && shouldPoll && checkStatus()
  }, [shouldWait, shouldPoll])

  useInterval(checkStatus, shouldWait && shouldPoll ? 3000 : null)

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </ContentContainer>
  )
})
