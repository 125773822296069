import { ContentContainer, LoadingSpinner } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../components/App'
import { forceRedirect } from '../methods/forceRedirect'

export const SaltedgeReturnPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  const location = window.location.href
  const url = new URL(location)

  useEffect(() => {
    forceRedirect(`${process.env.WEB_API_URL}/salt-edge/complete${url.search}`)
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </ContentContainer>
  )
})
