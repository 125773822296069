import { observer } from 'mobx-react'
import styled from 'styled-components'
import React, { FC, useContext } from 'react'
import { StoreContext } from './App'
import AmexHeaderLogo from '@assets/amex-header-logo.png'

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background-color: #fff;
`

export const TopBar: FC = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  if (theme.globals.name === 'Amex') {
    return (
      <Header>
        <img src={AmexHeaderLogo} alt="Amex logo" />
      </Header>
    )
  }

  return <></>
})
