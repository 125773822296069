import { action, flow, makeAutoObservable } from 'mobx'
import { scenarios } from '../constants/scenarios'
import { devLog } from '../methods/devLog'
import {
  ContextData,
  getOnboardingContext,
} from '../methods/getOnboardingContext'

export type ConfigurationNames =
  | 'ikano.de'
  | 'ikano.de.fleks'
  | 'ikano.de.pis'
  | 'ikano.de.ais'
  | 'ikano.de.b'
  | 'ikano.de.videoid'
  | 'ikano.de.addressVerification'
  | 'ikano.de.mock' // to implement
  | 'ikano.de.goToMobile'
  | 'ikano.de.goToMobile.mock'
  | 'ikano.addressVerification.test'
  | 'signicat.addressVerification.test'
  | 'jitpay.eu'
  | 'jitpay.qes'
  | 'jitpay.eu.micropayment'
  | 'bmw.eu'
  | 'bmw.pl'
  | 'bmw.de'
  | 'bmw.fr'
  | 'dtp.openBankingLogin'
  | 'dtp.openBankingLogin.waiting'
  | 'payment.authorization'

export type ScenarioNames =
  | 'videoId'
  | 'micropayment'
  | 'address-verification'
  | 'utility-bill-address-verification'
  | 'residence-permit-address-verification'
  | 'micropayment-fintec'
  | 'micropayment-klarna'
  | 'klarna-kosma'
  | 'klarna-kosma-pisp'
  | 'kontomatik' //OK
  | 'dev'
  | 'mock'
  | 'init'
  | 'pooling'
  | 'pooling-jitpay'
  | 'video-id-retry'
  | 'video-id-retry-ikano'
  | 'video-id-retry-jitpay'
  | 'switch-to-mobile'
  | 'continue-on-mobile'
  | 'eid-retry'

const contextMap = {
  // MAP OF MANGLING CONFIGURATION ID WITH SCENARIO NAMES
  videoId: 'videoId',
  'address-verification': 'address-verification',
  'utility-bill-address-verification': 'address-verification',
  'residence-permit-address-verification': 'address-verification',
  micropayment: 'micropayment-fintec',
  'micropayment-fintec': 'micropayment-fintec',
  'micropayment-klarna': 'micropayment-klarna',
  'klarna-kosma-pisp': 'micropayment-klarna',
  kontomatik: 'kontomatik',
  onboarding: 'onboarding',
}

const PAGES_WITHOUT_BASIC_CONTEXT = ['continue-on-mobile']

export class ScenarioStore {
  rootStore
  scenarioId: ScenarioNames = 'init'
  context: ContextData
  currentScenarioId = 'init'
  currentPageIndex = 0
  currentScenarioPages = scenarios.init
  env: string

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setScenarioId(scenarioId: ScenarioNames): void {
    this.scenarioId = scenarioId
  }

  @action.bound setCurrentPage(page: number): void {
    this.currentPageIndex = page
  }

  @action.bound init(scenarioId: ScenarioNames): void {
    this.setScenarioId(scenarioId)

    if (process.env.ONBOARDING_DEV === 'true') {
      this.setScenarioSteps()
    } else {
      this.initContext()
    }
  }

  @action.bound setEnv(env: string): void {
    this.env = env
  }

  @action.bound setContext(context): void {
    this.context = { ...this.context, ...context }
  }

  setScenarioSteps(): void {
    // language from context and query params
    if (this.context && this.context.language) {
      devLog('Setting translations from context: ', this.context.language)
      this.rootStore.TranslationsState.setTranslations(this.context.language)
    }

    let name = `${this.scenarioId}/${
      this.context && this.context.configurationId
        ? this.context.configurationId
        : ''
    }`
    let scenario = scenarios[name]

    devLog('Scenario: ', name, scenario)

    if (!scenario) {
      name = `${this.scenarioId}`
      scenario = scenarios[name]

      devLog('Scenario fallback: ', name, scenario)
    }

    this.currentScenarioPages = scenario
    this.currentScenarioId = name

    devLog(
      `Initialized Onboarding Flow\n`,
      'ConfigurationId: ',
      this.context && this.context.configurationId
        ? this.context.configurationId
        : 'null',
      '\n',
      'Context: ',
      this.context,
      '\n',
      'Current Scenario Id: ',
      this.currentScenarioId,
      '\n',
      'Pages: ',
      this.currentScenarioPages
    )
  }

  initContext = flow(function* (this: ScenarioStore) {
    try {
      if (!PAGES_WITHOUT_BASIC_CONTEXT.includes(this.scenarioId)) {
        const baseContext = yield getOnboardingContext('onboarding')
        this.context = { ...this.context, ...baseContext }
      }

      if (contextMap[this.scenarioId]) {
        const scenarioContext = yield getOnboardingContext(
          contextMap[this.scenarioId]
        )
        this.context = { ...this.context, ...scenarioContext }

        if (scenarioContext && scenarioContext.env) {
          this.env = scenarioContext.env
        }
      }

      //videoId env and flags in components point to context in this store

      this.setScenarioSteps()

      this.rootStore.TrackingState.initKeepSessionAliveInterval()
    } catch (error) {
      devLog(error)
    }
  })
}
